<template>
  <div class="clebex-calendar w-100">
    <header class="calendar-header" v-if="cal.expanded">
      <div class="calendar-title">
        {{ cal.parsedDateMonth() }} <span>{{ cal.parsedDateYear() }}</span>
      </div>
      <div class="month-switcher icon-app1">
        <button class="month prev" @click="prevMonth()">
          <icon icon="#cx-app1-arrow-left"></icon>
        </button>
        <button class="month current" @click="selectToday">
          <icon icon="#cx-app1-arrow-today"></icon>
        </button>
        <button class="month prev" @click="nextMonth()">
          <icon icon="#cx-app1-arrow-right"></icon>
        </button>
      </div>
    </header>
    <div class="calendar-wrapper header-calendar" ref="calendarWrapper">
      <template v-if="!cal.expanded">
        <ul class="days-of-the-week-labels">
          <li
            class="day-label"
            v-for="day in cal.labelsOfDaysSingleWeek()"
            v-bind:key="day"
          >
            {{ day }}
          </li>
        </ul>
        <ul class="days-of-the-week">
          <li
            class="day"
            v-for="day in cal.numbersOfDaysToDisplaySingleWeek()"
            :class="{
              today: cal.isToday(day.dateString),
              selected: cal.isSelected(day)
            }"
            :aria-date="day.dateString"
            v-bind:key="day"
          >
            <span class="day-button disabled" v-if="day.outOfRange">
              {{ day.dayNum }}
            </span>
            <button v-else class="day-button" @click="selectDate(day.dateObj)">
              {{ day.dayNum }}
            </button>
          </li>
        </ul>
      </template>
      <template v-else>
        <ul class="days-of-the-week-labels">
          <li
            class="day-label"
            v-for="day in cal.labelsOfDaysWholeMonth()"
            v-bind:key="day"
          >
            {{ day }}
          </li>
        </ul>
        <ul class="days-of-the-week">
          <li
            class="day"
            v-for="day in cal.numbersOfDaysToDisplayWholeMonth()"
            :class="[
              {
                today: cal.isToday(day.dateString),
                selected: cal.isSelected(day)
              },
              day.occupancy ? `occupancy ${day.occupancy}` : ''
            ]"
            :aria-date="day.dateString"
            v-bind:key="day"
          >
            <span class="day-button disabled" v-if="day.outOfRange">
              {{ day.dayNum }}
            </span>
            <button v-else class="day-button" @click="selectDate(day.dateObj)">
              {{ day.dayNum }}
            </button>
          </li>
        </ul>
      </template>
      <button
        class="expander"
        @click="cal.setExpanded()"
        :class="{ expanded: cal.expanded }"
      >
        <icon icon="#cx-app1-arrow-right-12x12" width="12" height="12"></icon>
      </button>
    </div>
  </div>
</template>

<script>
import ClebexCalendar from "@/functionalties/calendar";
import dayjs from "dayjs";
import { mapActions } from "vuex";
export default {
  name: "HeaderCalendar",
  data() {
    return {
      today: dayjs().startOf("day"),
      cal: Object.create(ClebexCalendar)
    };
  },
  created() {
    this.cal.expanded = false;
  },
  mounted() {
    if (!this.isMobileDevice()) {
      this.cal.expanded = true;
    }
  },
  methods: {
    ...mapActions("calendar", ["setStartDate"]),
    prevMonth() {
      this.cal.prevMonth();
      this.setStartDate(this.cal.currentMonth());
    },
    nextMonth() {
      this.cal.nextMonth();
      this.setStartDate(this.cal.currentMonth());
    },
    selectToday() {
      const today = dayjs().startOf("day");
      const monthIndex = today.month();
      this.setStartDate(today);
      this.cal.selectDate(today);
      if (this.cal.month() !== monthIndex) {
        this.cal.setSelectedMonth(monthIndex);
      }
    },
    selectDate(date) {
      this.setStartDate(date);
      this.cal.selectDate(date);
    }
  }
};
</script>
